import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import React from 'react';
import 'twin.macro';
import { StyledGeneratedHtmlContainer } from './privacy-e-cookie-policy';

const CookiePolicyPage = () => {
  return (
    <StyledGeneratedHtmlContainer tw="py-16">
      <SEO title="Privacy e cookie policy" url="/cookie-policy" />
      <section tw="mb-16">
        <Container>
          <Heading variant="h3" tw="text-primary-500 mb-0 text-5xl">
            COOKIE POLICY
          </Heading>
          <div>
            <p>
              <em>relativa al sito internet </em>
              <a href="http://www.hellougo.com">
                <em>www.hellougo.com</em>
              </a>
              <em> </em>
            </p>
            <p>
              Gentile <strong>Utente</strong>,
            </p>
            <p>
              la presente Cookie Policy si riferisce all’utilizzo di cookie da
              parte del sito internet{' '}
              <a href="http://www.hellougo.com">www.hellougo.com</a> (di seguito
              “<strong>Sito</strong>”) di proprietà della Ugo pnp S.r.l. (di
              seguito “<strong>UGO</strong>”) e costituisce parte integrante
              della nostra Privacy Policy.
            </p>
            <p>
              Quando visiti il Sito, questo potrebbe memorizzare o recuperare
              informazioni tramite il browser, principalmente sotto forma di
              cookie.
            </p>
            <p>
              Per garantirti la massima trasparenza nell’utilizzo dei cookie, ti
              informiamo che:
            </p>
            <ul>
              <li>
                la titolarità di alcuni cookie è direttamente di UGO (cookie di
                prima parte), mentre per altri appartiene a domini di terze
                parti (cookie di terze parti);
              </li>
              <li>
                alcuni cookie vengono cancellati alla chiusura del browser,
                mentre altri (quelli persistenti) hanno scadenze più lunghe, da
                pochi minuti a mesi interi, a seconda delle funzioni che
                svolgono.
              </li>
            </ul>
            <p>
              Utilizzando il nostro Sito, acconsenti all'utilizzo dei cookie in
              conformità a questa Cookie Policy.
            </p>
            <p>
              Puoi scegliere di non acconsentire ad alcuni tipi di cookie
              selezionando le categorie che preferisci dalle impostazioni che
              trovi all’interno del banner cookie che compare al tuo primo
              accesso al Sito.
            </p>
            <table>
              <tr>
                <td>
                  <p>
                    <strong>COSA SONO I COOKIE?</strong>
                  </p>
                </td>
                <td>
                  <p>
                    I Cookie sono elementi tecnici costituiti da piccole
                    stringhe di testo ed utilizzati dalle applicazioni web
                    all’interno delle comunicazioni nei protocolli HTTP e HTTPS:
                    essi sono molto utili poiché facilitano il collegamento tra
                    il client (dispositivo dell’Utente) ed il server (sito
                    internet visitato dall’Utente)
                  </p>
                </td>
              </tr>
            </table>
            <p>
              Noi di UGO utilizziamo i cookie per riconoscere le tue preferenze
              o il tuo dispositivo e per far funzionare il Sito come ti aspetti.
              I cookie hanno scopi diversi come, ad esempio, consentire
              all’Utente di navigare in modo efficiente tra le pagine,
              ricordando i suoi articoli salvati nel carrello e, in generale,
              migliorare l'esperienza di navigazione. Inoltre, contribuiscono a
              garantire che il contenuto pubblicitario visualizzato online sia
              più mirato a un Utente e ai suoi interessi.
            </p>
            <p>
              A seconda della funzione e dell'uso, i cookie possono essere
              suddivisi in cookie tecnici, funzionali, di analisi e di marketing
              e profilazione.
            </p>
            <table>
              <tr>
                <td>
                  <p>COOKIE TECNICI</p>
                </td>
                <td>
                  <p>DESCRIZIONE DEL COOKIE</p>
                </td>
                <td>
                  <p>DURATA</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Consent</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie tecnico, del protocollo http e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    verificare la tipologia di cookie scelti dall’Utente
                  </p>
                </td>
                <td>
                  <p>2 anni</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Cookie Consent</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie tecnico, del protocollo http e di prima parte (UGO),
                    utilizzato all’interno del Sito per verificare la tipologia
                    di cookie scelti dall’Utente
                  </p>
                </td>
                <td>
                  <p>1 anno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>Test_cookie</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie tecnico, del protocollo http e di terze parti
                    (Doubleclick.net), utilizzato all’interno del Sito per
                    verificare la tipologia di cookie scelti dall’Utente
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>@@scroll#</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie tecnico, del linguaggio html e di prima parte (UGO),
                    utilizzato all’interno del Sito per verificare le
                    interazioni con il Sito da parte dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>CookieConsentBulkSetting-#</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie tecnico, del linguaggio html e di terze parti
                    (Cookiebot.com), utilizzato all’interno del Sito per
                    verificare la tipologia di cookie scelti dall’Utente
                  </p>
                </td>
                <td>
                  <p>Persistente</p>
                </td>
              </tr>
            </table>
            <p>
              I cookie tecnici sono costantemente abilitati e sono necessari per
              eseguire le funzionalità di base del Sito. Si tratta di cookie che
              consentono di riconoscere l’Utente che utilizza il Sito durante
              un’unica sessione o, se richiesto, anche nelle sessioni
              successive. Questi cookie consentono di determinare se l’Utente ha
              accettato la casella del consenso sui cookie, risolvere problemi
              legati alla sicurezza e garantire conformità alle normative
              vigenti.
            </p>
            <p>
              In quanto essenziali al corretto funzionamento del sito, i cookie
              tecnici non possono essere disattivati tramite le funzioni del
              banner. Puoi impostare il tuo browser per bloccare questi cookie,
              ma di conseguenza il Sito non funzionerà correttamente.
            </p>
            <table>
              <tr>
                <td>
                  <p>COOKIE ANALITICI</p>
                </td>
                <td>
                  <p>DESCRIZIONE DEL COOKIE</p>
                </td>
                <td>
                  <p>DURATA</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_clck</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte
                    (UGO), utilizzato all’interno del Sito per verificare le
                    interazioni dell’Utente con il Sito e fornire statistiche
                  </p>
                </td>
                <td>
                  <p>1 anno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_clsk</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte
                    (UGO), utilizzato all’interno del Sito per verificare le
                    interazioni dell’Utente con il Sito e fornire statistiche
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_cltk</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del linguaggio html e di prima parte
                    (UGO), utilizzato all’interno del Sito per verificare le
                    interazioni dell’Utente con il Sito e fornire statistiche
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_ga</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Google.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>2 anni</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_ga_#</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Google.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>2 anni</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_gat</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Google.com), utilizzato all’interno del Sito monitorare
                    e gestire le richieste dell’Utente
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_gid</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Google.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjAbsoluteSessionInProgress</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjFirstSeen</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjIncludedInPageviewSample</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjIncludedInSessionSample</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjRecordingLastActivity</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del linguaggio html e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjSession_#</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjSessionUser_#</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>1 anno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>c.gif</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Pixel di terze parti (c.clarity.ms), utilizzato all’interno
                    del Sito per verificare le interazioni dell’Utente con il
                    Sito e fornire statistiche
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>CLID</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del protocollo http e di terze parti
                    (clarity.ms), utilizzato all’interno del Sito per verificare
                    le interazioni dell’Utente con il Sito e fornire statistiche
                  </p>
                </td>
                <td>
                  <p>1 anno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>hjViewportId</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie analitico, del linguaggio html e di prima parte (UGO
                    via Hotjar.com), utilizzato all’interno del Sito per
                    verificare le interazioni dell’Utente con il Sito e fornire
                    statistiche
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
            </table>
            <p>
              I cookie analitici sono cookie che permettono di contare le visite
              e fonti di traffico per l’elaborazione di report e statistiche
              sulle modalità di navigazione degli Utenti sul Sito. Questi
              cookie, ad esempio, mostrano le pagine del Sito più visitate dagli
              Utenti, consentono di verificare quali sono i modelli ricorrenti
              di utilizzo di un sito web, contribuiscono a comprendere tutte le
              difficoltà incontrate nell'utilizzo del sito web ed a mostrare
              l'efficacia della pubblicità implementata sul sito.
            </p>
            <p>
              Per consentirci l’utilizzo di cookie analitici potrai prestare il
              tuo consenso mediante il banner di ingresso al tuo primo accesso
              al nostro Sito.
            </p>
            <table>
              <tr>
                <td>
                  <p>MARKETING E PROFILAZIONE</p>
                </td>
                <td>
                  <p>DESCRIZIONE DEL COOKIE</p>
                </td>
                <td>
                  <p>DURATA</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_fbp</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di prima parte
                    (Ugo via Facebook.com), utilizzato all’interno del Sito per
                    attività di advertising
                  </p>
                </td>
                <td>
                  <p>3 mesi</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_gcl_au</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di prima parte
                    (Ugo via Google.com), utilizzato all’interno del Sito per
                    attività di advertising
                  </p>
                </td>
                <td>
                  <p>3 mesi</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>_hjRecordingEnabled</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di prima parte
                    (Ugo), utilizzato all’interno del Sito per ottimizzare
                    l’ambiente online sulla base delle preferenze dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>ads/ga-audiences</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Pixel di terze parti (Google.com), utilizzato all’interno
                    del Sito per attività di advertising
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>ANONCHK</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di terze parti
                    (c.clarity.ms), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>1 giorno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>IDE</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di terze parti
                    (Doubeclick.net), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>1 anno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>MUID</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di terze parti
                    (c.clarity.ms), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>1 anno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>pagead/landing</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Pixel di terze parti (Doubleclick.net), utilizzato
                    all’interno del Sito per attività di advertising
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>pagead/landing</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Pixel di terze parti (Google.com), utilizzato all’interno
                    del Sito per attività di advertising
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>SM</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di terze parti
                    (c.clarity.ms), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>SRM_B</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di terze parti
                    (Bing.com), utilizzato all’interno del Sito per ottimizzare
                    l’ambiente online sulla base delle preferenze dell’Utente
                  </p>
                </td>
                <td>
                  <p>1 anno</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>tr</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Pixel di terze parti (Facebook.com), utilizzato all’interno
                    del Sito per attività di advertising
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>VISITOR_INFO1_LIVE</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>179 giorni</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>YSC</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del protocollo http e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt.innertube::nextId</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Persistente</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt.innertube::requests</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Persistente</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt-remote-cast-available</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt-remote-cast-installed</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt-remote-connected-devices</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Persistente</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt-remote-device-id</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Persistente</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt-remote-fast-check-period</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt-remote-session-app</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <strong>yt-remote-session-name</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Cookie di marketing, del linguaggio html e di terze parti
                    (Youtube.com), utilizzato all’interno del Sito per
                    ottimizzare l’ambiente online sulla base delle preferenze
                    dell’Utente
                  </p>
                </td>
                <td>
                  <p>Sessione</p>
                </td>
              </tr>
            </table>
            <p>
              I cookie di marketing e profilazione possono essere impostati
              tramite il nostro Sito dai nostri partner pubblicitari. Possono
              essere utilizzati da queste aziende per creare profili relativi
              all'Utente al fine di inviare messaggi commerciali che incontrano
              le preferenze manifestate durante la visita. Questi cookie,
              inoltre, permettono di offrirti la nostra proposta commerciale su
              altri siti web, queste informazioni sono controllate totalmente
              dalle società terze secondo quanto descritto nelle rispettive
              privacy policy.
            </p>
            <p>
              Per consentirci l’utilizzo di cookie di marketing e profilazione
              potrai prestare il tuo consenso mediante il banner di ingresso al
              tuo primo accesso al nostro Sito. Se non acconsentirai
              all'utilizzo di questi cookie, riceverai una pubblicità meno
              mirata alle tue preferenze ed interessi.
            </p>
            <p>
              I cookie possono essere cancellati seguendo le istruzioni di
              seguito riportate.
            </p>
            <table>
              <tr>
                <td>
                  <p>
                    <strong>
                      COME POSSO CANCELLARE I COOKIE DAL MIO BROWSER?
                    </strong>
                  </p>
                </td>
                <td>
                  <p>
                    Puoi gestire i cookie tramite le impostazioni del tuo
                    browser. Di seguito riportiamo le modalità offerte dai
                    principali browser per eliminarli:
                  </p>
                  <p>
                    • In <strong>Internet Explorer</strong> seleziona il
                    pulsante Strumenti, scegli Sicurezza, quindi seleziona
                    Elimina cronologia esplorazioni. Selezionare la casella di
                    controllo cookie e dati sito Web e quindi selezionare
                    Elimina.
                  </p>
                  <p>
                    • In <strong>Chrome</strong> sul computer in alto a destra,
                    fai clic su Altro Altro e poi Impostazioni. Nella sezione
                    "Privacy e sicurezza", fai clic su Cookie e altri dati dei
                    siti. Fai clic su Mostra tutti i cookie e i dati dei siti e
                    poi Rimuovi tutto. Conferma facendo clic su Cancella tutto.
                  </p>
                  <p>
                    • In <strong>Firefox</strong> fare clic sul pulsante dei
                    Menu, successivamente su Libreria. Fare clic su Cronologia e
                    successivamente su Cancella la cronologia recente. Dal menu
                    a discesa Intervallo di tempo da cancellare: selezionare
                    tutto. Selezionare Cookie e assicurarsi che non siano
                    selezionati gli altri elementi che si intende conservare.
                    Fare clic su OK per eliminare i cookie, chiudere quindi la
                    finestra Cancella tutta la cronologia.
                  </p>
                  <p>
                    • In <strong>Safari</strong> fai clic su Safari, dalla barra
                    del menu in alto, e seleziona Preferenze. Nella finestra
                    Preferenze, clicca sulla scheda Privacy, e seleziona
                    Gestisci dati siti web. Quindi clicca su Rimuovi tutto.
                  </p>
                </td>
              </tr>
            </table>
            <p>
              Per maggiori informazioni sui cookie e per gestire le tue
              preferenze sui cookie di profilazione di terze parti ti invitiamo
              a visitare il sito internet
              <a href="http://www.youronlinechoices.com">
                http://www.youronlinechoices.com
              </a>
              . Una volta sul sito, accedendo all’area “Le tue scelte” potrai
              verificare la presenza e lo stato dei cookie di terze parti
              installati e gestirne il consenso.
            </p>
            <p>
              La presente Cookie Policy potrebbe subire modifiche e/o
              integrazioni nel tempo, anche quale conseguenza di variazioni
              della normativa applicabile. Potrai visionare il testo della
              Cookie Policy costantemente aggiornata all’interno del nostro
              Sito, sezione Cookie Policy, o effettuare esplicita richiesta
              contattando direttamente il Titolare e/o il DPO.
            </p>
            <p>Ultimo aggiornamento: marzo ’22</p>
          </div>
        </Container>
      </section>
    </StyledGeneratedHtmlContainer>
  );
};

export default CookiePolicyPage;
